import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { 
  Drawer,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SpeakerNotesIcon from '@material-ui/icons//SpeakerNotes';
import BookIcon from '@material-ui/icons//Book';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@mui/icons-material/Settings';

import { UserContext } from '@context';
import { DialogContext } from '@context/dialogs';
import { EventsContext } from '@context/events';

export default function MenuDrawer(props){
  const { isOpen, close } = props;
  const { user, handleLogout } = useContext(UserContext);
  const { trackEvent } = useContext(EventsContext);
  const { openFeedbackDialog } = useContext(DialogContext);
  const history = useHistory();

  const goTo = (location) => {
    close();
    history.push(location);
  };

  const signOut = () => {
    trackEvent('User:LogOut');
    handleLogout(() => {
      history.push('/index');
    });
  };

  return (

    <Drawer open={isOpen} onClose={close}>
      <Box 
        sx={{  
          height: 'calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
          marginTop: 'env(safe-area-inset-top)',
          display: 'flex',
          flexDirection: 'column',
          width:250
        }}
      >
        <Box flexGrow={1}>
          <ListItem onClick={() => goTo(`?profileId=${user?.id}`)} data-testid='menu-drawer-profile-button'>
            <ListItemIcon><PersonIcon /></ListItemIcon>
            <ListItemText primary={'Profile'} />
          </ListItem>
          <ListItem onClick={() => openFeedbackDialog()}>
            <ListItemIcon><SpeakerNotesIcon /></ListItemIcon>
            <ListItemText primary={'Support Chat'} />
          </ListItem>
          <ListItem button
            component="a"
            href="https://www.bracesocial.com/blog"
            target="_blank">
            <ListItemIcon><BookIcon /></ListItemIcon>
            <ListItemText primary={'Brace Blog'} />
          </ListItem>
          <ListItem button component={Link} to='/settings'>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary={'Settings'} />
          </ListItem>
        </Box>
        <Box>
          <ListItem onClick={signOut} data-testid='menu-drawer-button-log-out'>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary={'Log Out'} />
          </ListItem>
        </Box>
      </Box>
    </Drawer>
  );
}