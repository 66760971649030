import React from 'react';

import { 
  Avatar,
  Box
} from '@mui/material';
import { useTheme } from '@emotion/react';
import { Icon } from '../atoms';

import config from '@config';

export default function UserAvatar(props){
  const { size = 40, user, profile, onClick, dataTestId } = props;
  const theme = useTheme();

  var id = user?.id ?
    user.id :
    profile?.id;

  var doesUserHaveImage = true;

  if(user?.hasImage == false){
    doesUserHaveImage = false;
  }

  var src = id && doesUserHaveImage ? `${config.profileImagesBaseUrl}images/user-profile-image-${id}` : '';

  if(user && doesUserHaveImage){
    src = `${src}?lastmod=${user.lastMod}`;
  }

  const isPro = user?.isAccountOnboardingComplete;
  var proIconSize = size / 2.5;

  if(proIconSize > 25){
    proIconSize = 25;
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Avatar 
        data-testid={dataTestId}
        onClick={onClick}
        src={src}
        sx={{ backgroundColor: theme.palette.braceGreenLight.main }} 
      >
        <Icon 
          size={size * 0.75}
          type='user'
          fill={theme.palette.braceGreenDark.main}
          stroke={theme.palette.braceGreenDark.main}/>
      </Avatar>
      {isPro &&
        <Box sx={{ position: 'absolute', bottom: -(proIconSize / 4), right: -(proIconSize / 4), lineHeight: 1 }}>
          <Box sx={{ 
            backgroundColor: theme.palette.braceGreenLight.main, 
            borderRadius: '50%',
            width: proIconSize,
            height: proIconSize,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Icon type='star' size={proIconSize * 0.75}/>
          </Box>
        
        </Box>
      }

    </Box>
  );
}