import React, { useContext, useState, useEffect } from 'react';

import {
  Box,
  Stack,
  Typography,
  Card,
  Checkbox,
  Chip,
  Button
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import PersonIcon from '@mui/icons-material/Person';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';

import Trial from './trial';
import Image from 'mui-image';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { LoadingButton } from '@mui/lab';
import Stepper from './stepper';
import { EventsContext, FeaturesContext, BillingContext } from '@context';
import { DeleteAccountDialog } from '@components/v2/organisms';
import RedeemOfferSection from './redeem-offer-section';
import { billingPeriodToNumberOfMonths, billingPeriodToFrequency, billingPeriodToPlanTitle } from '@helpers/text';
import { AccountOnboardingContext } from '@context/account-onboarding';

export default function Paywall(props) {
  const { handleDelete } = props;
  const { subscription, offerings, hasActiveSubscription, purchasePackage, purchaseDiscount, isPurchasingPackage, isPollingForSubscription, cancelPollingForSubscription } = useContext(BillingContext);
  const { accountOnboarding } = useContext(AccountOnboardingContext);
  const { trackEvent } = useContext(EventsContext);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [isDeleteAccountOpen, setIsDeleteAccountOpen] = useState(false);
  const [shouldShowDropOffScreen, setShouldShowDropOffScreen] = useState(false);
  const [showDiscountCodeInput, setShowDiscountCodeInput] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const { isFeatureEnabled } = useContext(FeaturesContext);

  const theme = useTheme();
  const history = useHistory();

  const handlePurchase = () => {
    if(selectedDiscount){
      trackEvent('Payments:Subscription:Discount:Selected');
      purchaseDiscount(selectedDiscount, () => {
        trackEvent('Payments:Subscription:Discount:Purchase');
        history.push('/');
      });
    }else{
      trackEvent('Payments:Subscription:Plan:Selected');
      purchasePackage(selectedPackage.rcOffering, () => {
        trackEvent('Payments:Subscription:Plan:Purchase');
        history.push('/');
      });
    }

  };

  const BulletPoint = (props) => {
    const { title, message, icon } = props;

    return (
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ 
          color: theme.palette.braceGreenDark.main
        }}>
          {icon}
        </Box>
        <Box sx={{ paddingLeft: 2 }}>
          <Box>
            <Typography variant='body1' component='div'>
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1'>
              {message}
            </Typography>
          </Box>
        </Box>
        
      </Box>
    );
  };

  const calculateSaving = (offering) => {
    var highestPricePerMonth = 0;

    offerings.forEach(o => {
      var pricePerMonth = o.rcOffering?.product?.pricePerMonth;
      if(pricePerMonth > highestPricePerMonth){
        highestPricePerMonth = pricePerMonth;
      }
    });

    var absoluteSaving = highestPricePerMonth - offering.rcOffering?.product?.pricePerMonth;

    return Math.round((absoluteSaving * 100) / highestPricePerMonth);

  };

  const Offering = (props) => {
    const { offering } = props;

    const isSelected = selectedPackage?.id == offering.id;
    const availableDiscount = discounts.filter(d => d.id == offering.id)[0];
    const hasAvailableDiscount = availableDiscount != null;
    
    var pricePerMonthString = offering.rcOffering?.product?.pricePerMonthString;
    var title = `${billingPeriodToPlanTitle(offering.period)} - ${offering.price}`;
    var message = `${pricePerMonthString}/mo when billed ${billingPeriodToFrequency(offering.period)}`;
    var saving = 0;

    if(hasAvailableDiscount){
      if(availableDiscount?.freePeriod){
        title = `${billingPeriodToNumberOfMonths(availableDiscount.freePeriod)} Free`;
        message = `then ${pricePerMonthString}/mo billed ${billingPeriodToFrequency(offering.period)}`;
      }else if(availableDiscount?.discountedPeriod){
        var price = availableDiscount?.prices ?
          availableDiscount?.prices[offering.currencyCode] : 
          availableDiscount.defaultPrice;

        if(availableDiscount.offer){
          var offerPrice = availableDiscount.offer?.introPhase?.price?.formatted;

          if(offerPrice){
            price = offerPrice;
          }
        }
       
        title = `${billingPeriodToNumberOfMonths(availableDiscount.discountedPeriod)} for ${price}`;
        message = `${offering.price} there after billed ${billingPeriodToFrequency(availableDiscount?.discountedPeriod)}`;
      }
    }else{
      saving = calculateSaving(offering);
    }

    return (
      <Card variant='outlined'
        sx={{ display: 'flex', width: '100%', p: 2, borderColor: isSelected ? theme.palette.braceGreenDark.main : theme.palette.divider }}
        onClick={() => {
          setSelectedDiscount(availableDiscount);
          setSelectedPackage(offering);
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display : 'flex', width: '100%', alignItems: 'center' }}>
            <Checkbox
              size='large'
              onClick={() => setSelectedPackage(offering)}
              sx={{ p: 0 }}
              checked={isSelected}
              icon={<RadioButtonUncheckedIcon fontSize='small' />}
              checkedIcon={<CheckCircleIcon fontSize='small' color='braceGreenDark' />} />

            <Typography variant='body1' sx={{ flexGrow: 1, paddingLeft: 1 }}>
              {title}
            </Typography>
            {availableDiscount?.discountPercent > 0 &&
              <Chip 
                variant='filled'
                color='braceGreenDark'
                size='small'
                label= {`${availableDiscount.discountPercent * 100}% OFF`}/>
            }
            {(!availableDiscount?.discountPercent && saving > 0) &&
              <Chip 
                variant={isSelected ? 'filled' : 'outlined'}
                color='braceGreenDark'
                size='small'
                label= {`${saving}% OFF`}/>
            }
          </Box>
          <Box>
            <Typography variant='body1'>
              {message}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1'>
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  };

  useEffect(() => {
    setShowDiscountCodeInput(false);
    if(subscription?.id){
      trackEvent('Payments:Paywall:Open');
    }
  }, []);

  const isAccountOnboardingComplete = accountOnboarding?.isComplete;

  return (
    <Box sx={{ height: '100%', backgroundColor: '#FAFAFA' }}>
      {!subscription && <Trial openDeleteAccount={() => setIsDeleteAccountOpen(true)} />}
      {(subscription && !shouldShowDropOffScreen) && 
      <>
        <Box sx={{ py: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box>
            <Image 
              duration={500} 
              width='180px' 
              src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_Dark and grad.png'/>
          </Box>
          <Box sx={{ p: 2, paddingTop: 2, flexGrow: 1, color: theme.palette.braceGreenDark.main }}>
            <Typography variant='h4' fontWeight={'medium'} textAlign='center' >
                Empower Your Recovery, Come Back Stronger
            </Typography>
            <Box sx={{ paddingTop: 2 }}>
              <BulletPoint title='Prioritise Your Health & wellbeing' icon={<AddIcon />}/>
              <BulletPoint title='Stay On Track With Your Recovery Plan' icon={<DoneIcon />}/>
              <BulletPoint title='Access Community Insights & Motivation' icon={<PersonIcon />}/>
              {isAccountOnboardingComplete &&  <BulletPoint title={<span>Brace Pro Discount Code: <b>BRACEPRO10</b></span>} icon={<StarIcon />}/>}
              {!isAccountOnboardingComplete &&  <BulletPoint title='10% off for Brace Pros' icon={<StarIcon />}/>}
            </Box>
            <Stack spacing={1} sx={{ paddingTop: 2 }}>
              {offerings.map(o => <Offering key={o.id} offering={o}/>)}
            </Stack>
          </Box>
          {showDiscountCodeInput && 
            <RedeemOfferSection close={() => setShowDiscountCodeInput(false)}
              handleDiscounts={(d) => {
                setSelectedPackage(null);
                setDiscounts(d);
              }}/>
          }
          {(!isPollingForSubscription && !showDiscountCodeInput  && discounts?.length > 0) && 
            <Typography>Discount Code Applied!</Typography>
          }
          {isPollingForSubscription &&
            <Typography>Checking For Subscription...</Typography>
          }
          {!showDiscountCodeInput && 
            <>
              {(discounts?.length == 0 && isFeatureEnabled('discount_codes')) &&
                <Button 
                  sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
                  size='small'
                  color='braceGreenDark'
                  onClick={() => setShowDiscountCodeInput(true)}
                >
                  Have a discount code?
                </Button>
              }
              <Box sx={{ width: '100%', p: 2 }}>
                <LoadingButton 
                  sx={{ py: 1, px: 4, fontSize: 'h6.fontSize' }}
                  size='large'
                  variant='contained'
                  color='braceGreenDark'
                  fullWidth={true}
                  disabled={!selectedPackage}
                  loading={isPurchasingPackage || isPollingForSubscription}
                  onClick={handlePurchase}
                >
                  {selectedDiscount ? 'CONTINUE TO OFFER' : 'CONTINUE'}
                </LoadingButton>
              </Box>
            </>
          }

          {(isPollingForSubscription) &&
           <Button 
             sx={{ px: 4 }}
             color='braceGreenDark'
             fullWidth={true}
             onClick={cancelPollingForSubscription}
           >
            Cancel
           </Button>
          }

          {(!isPollingForSubscription && hasActiveSubscription()) &&
           <Button 
             sx={{ px: 4 }}
             color='braceGreenDark'
             fullWidth={true}
             onClick={() => history.goBack()}
           >
            Cancel
           </Button>
          }
          {(!isPollingForSubscription && !hasActiveSubscription()) &&
              <Button 
                sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
                size='small'
                color='braceGreenDark'
                onClick={() => setShouldShowDropOffScreen(true)}
              >
                Not Right Now
              </Button>
          }

        </Box>
      </>
      }
      {(subscription && shouldShowDropOffScreen) &&
        <Box sx={{ py: 4, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 2, paddingTop: 3 }}>
            <Image 
              duration={500} 
              width='200px' 
              src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_Dark and grad.png'/>
          </Box>
          <Box sx={{ px: 2, paddingBottom: 1, fontWeight: 'medium', textAlign: 'center', color: theme.palette.braceGreenLight.main }}>
            <Typography variant='h5'>Recovery Is A Tough Journey.</Typography>
          </Box>
          <Box sx={{ px: 1, fontWeight: 'medium', textAlign: 'center', color: theme.palette.braceGreenDark.main  }}>
            <Typography variant='h6'>Brace is empowering our community with the physical and mental health supports to come back stronger, together.</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Stepper />
          </Box>
          <Box sx={{ px: 2, paddingBottom: 3, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button 
              sx={{ marginX: 2, marginTop: 2, marginBottom: 1, py: 1, px: 4, fontSize: 'h6.fontSize', width: '66%', minWidth: '250px' }}
              size='large'
              variant='contained'
              color='braceGreenLight'
              onClick={() => setShouldShowDropOffScreen(false)}
            >
              View Plans
            </Button>
            <Button 
              sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
              size='small'
              color='braceGreenDark'
              onClick={() => setIsDeleteAccountOpen(true)}
            >
                Delete Account
            </Button>
          </Box>
        </Box>
      }

      <DeleteAccountDialog
        deleteAccount={handleDelete}
        isOpen={isDeleteAccountOpen} 
        close={() => setIsDeleteAccountOpen(false)}
      />
    </Box>
  );
}