const ONE_MONTH_FREE = [
  {
    id: '$rc_monthly',
    promoCode: 'BRACEFIRSTMONTHFREE',
    freePeriod: 'P1M'
  },
  {
    id: '$rc_six_month',
    promoCode: 'BRACEFIRSTMONTHFREESIXMONTHS',
    freePeriod: 'P1M'
  }
];

const FIRST_PERIOD_HALF_PRICE = [
  {
    id: '$rc_monthly',
    promoCode: 'BRACE1MONTHHALFPRICE',
    offerId: 'brace-subscription-monthly:first-month-half-price',
    discountedPeriod: 'P1M',
    discountPercent: 0.5,
    prices: { 'USD':'$3.99', 'AUD':'$5.99', 'BRL':'R$19.90', 'BGN':'BGN 8.99', 'CAD':'$3.99', 'CLP':'$2,990.00', 'CNY':'¥28.00', 'COP':'$19,900.00', 'CZK':'Kč 99.00', 'DKK':'kr 29.00', 'EGP':'E£199.99', 'EUR':'€2.99', 'HKD':'$28.00', 'HUF':'Ft 1,490.00', 'INR':'₹399.00', 'IDR':'Rp 59,000.00', 'ILS':'₪14.90', 'JPY':'¥600.00', 'KZT':'₸1,990.00', 'KRW':'₩5,500.00', 'MYR':'RM 19.90', 'MXN':'$89.00', 'NZD':'$6.99', 'NGN':'₦5,900.00', 'NOK':'kr 49.00', 'PKR':'Rs 1,100.00', 'PEN':'PEN 14.90', 'PHP':'₱199.00', 'PLN':'zł 19.99', 'QAR':'QAR 14.99', 'RON':'lei 19.99', 'RUB':'₽349.00', 'SAR':'SAR 14.99', 'SGD':'$4.98', 'ZAR':'R 69.99', 'SEK':'kr 49.00', 'CHF':'CHF 4.00', 'TWD':'$120.00', 'TZS':'TZS 11,900.00', 'THB':'฿149.00', 'TRY':'₺149.99', 'AED':'AED 14.99', 'GBP':'£2.99', 'VND':'₫99,000.00' },
    defaultPrice: '$3.99'
  },
  {
    id: '$rc_six_month',
    discountedPeriod: 'P6M',
    promoCode: 'BRACE6MONTHSHALFPRICE',
    offerId: 'brace-subscription-6-monthly:first-6-months-half-price',
    discountPercent: 0.5,
    prices: { 'USD':'$19.99', 'AUD':'$19.99', 'BRL':'R$99.90', 'BGN':'BGN 39.99', 'CAD':'$24.99', 'CLP':'$19,990.00', 'CNY':'¥148.00', 'COP':'$99,900.00', 'EUR':'€14.99', 'CZK':'Kč 499.00', 'DKK':'kr 149.00', 'EGP':'E£999.99', 'HKD':'$148.00', 'HUF':'Ft 8,990.00', 'INR':'₹1,999.00', 'IDR':'Rp 349,000.00', 'ILS':'₪69.90', 'JPY':'¥3,000.00', 'KZT':'₸9,990.00', 'KRW':'₩29,000.00', 'MYR':'RM 99.90', 'MXN':'$399.00', 'NZD':'$29.99', 'NGN':'₦29,900.00', 'NOK':'kr 249.00', 'PKR':'Rs 4,900.00', 'PEN':'PEN 69.90', 'PHP':'₱999.00', 'PLN':'zł 99.99', 'QAR':'QAR 69.99', 'RON':'lei 99.99', 'RUB':'₽1,790.00', 'SAR':'SAR 79.99', 'SGD':'$29.98', 'ZAR':'R 399.99', 'SEK':'kr 249.00', 'CHF':'CHF 20.00', 'TWD':'$690.00', 'TZS':'TZS 49,900.00', 'THB':'฿699.00', 'TRY':'₺699.99', 'AED':'AED 69.99', 'GBP':'£14.99', 'VND':'₫499,000.00' },
    defaultPrice: '$19.99'
  },
  {
    id: '$rc_annual',
    discountedPeriod: 'P1Y',
    promoCode: 'BRACE1YEARHALFPRICE',
    offerId: 'brace-subscription-annual:first-year-half-price',
    prices: { 'USD':'$29.99', 'AUD':'$49.99', 'EUR':'€24.99', 'BRL':'R$149.90', 'BGN':'BGN 59.99', 'CAD':'$39.99', 'CLP':'$29,990.00', 'CNY':'¥198.00', 'COP':'$149,900.00', 'CZK':'Kč 699.00', 'DKK':'kr 249.00', 'EGP':'E£1,499.99', 'HKD':'$228.00', 'HUF':'Ft 12,990.00', 'INR':'₹2,999.00', 'IDR':'Rp 499,000.00', 'ILS':'₪99.90', 'JPY':'¥4,500.00', 'KZT':'₸14,990.00', 'KRW':'₩44,000.00', 'MYR':'RM 129.90', 'MXN':'$599.00', 'NZD':'$49.99', 'NGN':'₦49,900.00', 'NOK':'kr 399.00', 'PKR':'Rs 6,900.00', 'PEN':'PEN 119.90', 'PHP':'₱1,790.00', 'PLN':'zł 149.99', 'QAR':'QAR 99.99', 'RON':'lei 149.99', 'RUB':'₽2,490.00', 'SAR':'SAR 129.99', 'SGD':'$39.98', 'ZAR':'R 599.99', 'SEK':'kr 399.00', 'CHF':'CHF 30.00', 'TWD':'$990.00', 'TZS':'TZS 89,900.00', 'THB':'฿999.00', 'TRY':'₺1,499.99', 'AED':'AED 99.99', 'GBP':'£19.99', 'VND':'₫799,000.00' },
    discountPercent: 0.5,
    defaultPrice: '$29.99'
  }
];

const FIRST_PERIOD_TEN_PERCENT_OFF = [
  {
    id: '$rc_monthly',
    promoCode: 'BRACE1MONTH10PERCENTOFF',
    offerId: 'brace-subscription-monthly:first-month-10-percent-off',
    discountedPeriod: 'P1M',
    discountPercent: 0.1,
    prices: { 'USD':'$7.19', 'AUD':'$11.49', 'BRL':'R$35.90', 'BGN':'BGN 16.00', 'CAD':'$7.19', 'CLP':'$6,290.00', 'CNY':'¥52.00', 'COP':'$35,900.00', 'CZK':'Kč 179.00', 'DKK':'kr 62.00', 'EGP':'E£359.99', 'EUR':'€6.29', 'HKD':'$52.00', 'HUF':'Ft 3,100.00', 'INR':'₹799.00', 'IDR':'Rp 116,000.00', 'ILS':'₪26.90', 'JPY':'¥1,080.00', 'KZT':'₸1,990.00', 'KRW':'₩3,590.00', 'MYR':'RM 35.90', 'MXN':'$161.00', 'NZD':'$13.49', 'NGN':'₦11,600.00', 'NOK':'kr 89.00', 'PKR':'Rs 1,949.00', 'PEN':'PEN 26.90', 'PHP':'₱359.00', 'PLN':'zł 35.99', 'QAR':'QAR 26.99', 'RON':'lei 35.99', 'RUB':'₽629.00', 'SAR':'SAR 31.49', 'SGD':'$8.98', 'ZAR':'R 134.99', 'SEK':'kr 134.99', 'CHF':'CHF 7.20', 'TWD':'$260.00', 'TZS':'TZS 20,500.00', 'THB':'฿249.00', 'TRY':'₺269.99', 'AED':'AED 26.99', 'GBP':'£5.39', 'VND':'₫179,000.00' },
    defaultPrice: '$7.19'
  },
  {
    id: '$rc_six_month',
    discountedPeriod: 'P6M',
    promoCode: 'BRACE6MONTHS10PERCENTOFF',
    offerId: 'brace-subscription-6-monthly:first-6-months-10-percent-off',
    discountPercent: 0.1,
    prices: { 'USD':'$35.99', 'AUD':'$35.99', 'BRL':'R$179.90', 'BGN':'BGN 80.99', 'CAD':'$44.99', 'CLP':'$$35,990.00', 'CNY':'¥268.00', 'COP':'$179,900.00', 'EUR':'€31.49', 'CZK':'Kč 899.00', 'DKK':'kr 310.00', 'EGP':'E£1,799.99', 'HKD':'$259.00', 'HUF':'Ft 16,190.00', 'INR':'₹3,599.00', 'IDR':'Rp 629,000.00', 'ILS':'₪134.90', 'JPY':'¥5,400.00', 'KZT':'₸17,990.00', 'KRW':'₩49,500.00', 'MYR':'RM 179.90', 'MXN':'$809.00', 'NZD':'$62.99', 'NGN':'₦62,900.00', 'NOK':'kr 449.00', 'PKR':'Rs 8,900.00', 'PEN':'PEN 134.90', 'PHP':'₱2,050.00', 'PLN':'zł 179.99', 'QAR':'QAR 134.99', 'RON':'lei 179.99', 'RUB':'₽3,100.00', 'SAR':'SAR 161.99', 'SGD':'$53.98', 'ZAR':'R 719.99', 'SEK':'kr 449.00', 'CHF':'CHF 36.00', 'TWD':'$1,160.00', 'TZS':'TZS 89,900.00', 'THB':'฿1,290.00', 'TRY':'₺1,349.99', 'AED':'AED 134.99', 'GBP':'£26.99', 'VND':'₫899,000.00' },
    defaultPrice: '$35.99'
  },
  {
    id: '$rc_annual',
    discountedPeriod: 'P1Y',
    promoCode: 'BRACE1YEAR10PERCENTOFF',
    offerId: 'brace-subscription-annual:first-year-10-percent-off',
    prices: { 'USD':'$53.99', 'AUD':'$89.99', 'EUR':'€44.99', 'BRL':'R$269.90', 'BGN':'BGN 116.99', 'CAD':'71.99', 'CLP':'$53,990.00', 'CNY':'¥358.00', 'COP':'$269,900.00', 'CZK':'Kč 1,340.00', 'DKK':'kr 449.00', 'EGP':'E£2,699.99', 'HKD':'$439.00', 'HUF':'Ft 22,490.00', 'INR':'₹5,300.00', 'IDR':'Rp 899,000.00', 'ILS':'₪179.90', 'JPY':'¥8,100.00', 'KZT':'₸26,990.00', 'KRW':'₩79,000.00', 'MYR':'RM 269.90', 'MXN':'$1,169.00', 'NZD':'$89.99', 'NGN':'₦89,900.00', 'NOK':'kr 719.00', 'PKR':'Rs 13,400.00', 'PEN':'PEN 204.90', 'PHP':'₱3,100.00', 'PLN':'zł 269.99', 'QAR':'QAR 179.99', 'RON':'lei 269.99', 'RUB':'₽4,490.00', 'SAR':'SAR 224.99', 'SGD':'$80.98', 'ZAR':'R 1,079.99', 'SEK':'kr 719.00', 'CHF':'CHF 54.00', 'TWD':'$1,790.00', 'TZS':'TZS 161,900.00', 'THB':'฿1,790.00', 'TRY':'₺2,049.99', 'AED':'AED 204.99', 'GBP':'£40.49', 'VND':'₫1,349,000.00' },
    discountPercent: 0.1,
    defaultPrice: '$53.99'
  }
];

const OFFERS = {
  'BRACE1MONTHFREE': ONE_MONTH_FREE,
  'SPORTSTAPE': FIRST_PERIOD_HALF_PRICE,
  'BRACEPRO10': FIRST_PERIOD_TEN_PERCENT_OFF
};

const EXPIRED_OFFERS = [];

export const getDiscountDetails = (discountCode) => {
  if(!OFFERS[discountCode]){
    return {
      isError: true,
      errorMessage: EXPIRED_OFFERS.includes(discountCode) ? 'Expired Offer Code' : 'Invalid discount code'
    };
  }
  return OFFERS[discountCode];
};