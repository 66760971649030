import React, { useContext, useEffect, useState } from 'react';

import {
  Box,
  Link,
  Stack,
  Typography,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { useTheme } from '@emotion/react';

import Image from 'mui-image';
import { BillingContext } from '@context/billing';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSnackbar } from 'notistack';

import Stepper from './stepper';
import { EventsContext } from '@context/events';

export default function Trial(props) {
  var theme = useTheme();
  const { openDeleteAccount } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { startFreeTrial } = useContext(BillingContext);
  const { trackEvent } = useContext(EventsContext);
  const [ isStartingTrial, setIsStartingTrial ] = useState(false);
  const [ shouldShowDropOffScreen, setShouldShowDropOffScreen ] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if(shouldShowDropOffScreen){
      trackEvent('Payments:Trial:DropOffScreen');
    }
  }, [shouldShowDropOffScreen]);

  useEffect(() => {
    trackEvent('Payments:Trial:Open');
  }, []);

  const BulletPoint = (props) => {
    const { title, message, icon } = props;

    return (
      <Box sx={{ display: 'flex', paddingLeft: 2 }}>
        <Box sx={{ 
          background: theme.palette.braceGreenDark.main, 
          borderRadius: '50%', 
          width:'50px',
          minWidth:'50px',
          height:'50px',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          color: theme.palette.braceGreenLight.main
        }}>
          {icon}
        </Box>
        <Box sx={{ paddingLeft: 2 }}>
          <Box>
            <Typography variant='body1' fontWeight='medium'>
              {title}
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1'>
              {message}
            </Typography>
          </Box>
        </Box>
        
      </Box>
    );
  };

  const startTrial = async () => {
    setIsStartingTrial(true);
    await startFreeTrial();
    setIsStartingTrial(false);
    enqueueSnackbar('Successfully started you Brace 7 day trial.', { 
      variant: 'success'
    });
    trackEvent('Payments:Trial:Started');
    history.push('/');
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: 2, paddingTop: 3 }}>
        <Image 
          duration={500} 
          width='200px' 
          src='https://bracesocial-prod-cdn.azureedge.net/static/Brace_Logo_Master_Dark and grad.png'/>
      </Box>
      {!shouldShowDropOffScreen &&
      <>
        <Box sx={{ px: 2, fontWeight: 'medium', textAlign: 'center' }}>
          <Typography variant='h4'>{'Empower Your Recovery, First Week\'s On Us.'}</Typography>
        </Box>
        <Stack sx={{ flexGrow: 1, justifyContent: 'space-evenly' }}>
          <BulletPoint title='Today: Start Your Free Trial' message='Join today with no commitment.' icon={<KeyIcon />}/>
          <BulletPoint title='Days 1-7: Unlimited Access' message='Everything needed to kickstart your physical and mental recovery' icon={<PersonIcon />}/>
          <BulletPoint title='Day 7: Choose Your Subscription' message='Choose the subscription offer that suits your recovery needs.' icon={<LockIcon />}/>
        </Stack>
        <Box sx={{ px: 2, paddingBottom: 3, textAlign: 'center' }}>
          <Box>
            <Typography variant='body1'>We'll ask you at the end of your trial if you'd like to subscribe to a Brace Plan.</Typography>
          </Box>
          <LoadingButton 
            sx={{ marginX: 2, marginTop: 2, marginBottom: 1, py: 1, px: 4, fontSize: 'h6.fontSize', minWidth: '75%' }}
            size='large'
            variant='contained'
            color='braceGreenDark'
            onClick={startTrial}
            loading={isStartingTrial}
          >
            Continue To Brace
          </LoadingButton>
          <Button 
            sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
            size='small'
            color='braceGreenDark'
            onClick={() => setShouldShowDropOffScreen(true)}
          >
            No Thanks
          </Button>
          <Link target='_blank' href='https://www.bracesocial.com/policies' sx={{ display: 'flex', justifyContent: 'center', textDecoration: 'none', color: theme.palette.braceGreenDark.main  }}>
            <Box>
            Terms
            </Box>
            <Box mx={1}>
            &bull;
            </Box>
            <Box>
            Privacy
            </Box>
          </Link>
        </Box>
      </>
      }
      {shouldShowDropOffScreen &&
      <>
        <Box sx={{ px: 2, paddingBottom: 1, fontWeight: 'medium', textAlign: 'center', color: theme.palette.braceGreenLight.main }}>
          <Typography variant='h5'>Recovery Is A Tough Journey.</Typography>
        </Box>
        <Box sx={{ px: 1, fontWeight: 'medium', textAlign: 'center', color: theme.palette.braceGreenDark.main  }}>
          <Typography variant='h6'>Brace is empowering our community with the physical and mental health supports to come back stronger, together.</Typography>
        </Box>
        <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Stepper/>
        </Box>
        <Box sx={{ px: 2, paddingBottom: 3, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button 
            sx={{ marginX: 2, marginTop: 2, marginBottom: 1, py: 1, px: 4, fontSize: 'h6.fontSize', width: '66%', minWidth: '250px' }}
            size='large'
            variant='contained'
            color='braceGreenLight'
            onClick={() => {
              trackEvent('Payments:Trial:Reengagment');
              setShouldShowDropOffScreen(false);
            }}
          >
            Continue
          </Button>
          <Button 
            sx={{ textDecoration: 'underline', ':hover': { textDecoration: 'underline' } }}
            size='small'
            color='braceGreenDark'
            onClick={openDeleteAccount}
          >
            Delete Account
          </Button>
        </Box>
      </>
      }
    </Box>
  );
}